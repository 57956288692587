import React, { useRef, useState } from "react";
import emailjs from "@emailjs/browser";
import { toast, ToastContainer } from "react-toastify";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import Footer from "../Footer/Footer";
import ReCAPTCHA from "react-google-recaptcha";
import {
  RiInstagramLine,
  RiGithubLine,
  RiLinkedinLine,
  RiFacebookLine,
} from "react-icons/ri";
import "react-toastify/dist/ReactToastify.css";
import "./ContactMe.css";

export default function ContactMe(props) {
  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };

  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  const form = useRef();
  const [formErrors, setFormErrors] = useState({});
  const [recaptchaValue, setRecaptchaValue] = useState(null);

  const validateForm = () => {
    const errors = {};
    let isValid = true;

    const nameInput = form.current.from_name;
    const emailInput = form.current.from_email;
    const messageInput = form.current.message;

    if (nameInput.value.trim() === "") {
      errors.name = "Please enter your name";
      isValid = false;
    }

    if (emailInput.value.trim() === "") {
      errors.email = "Please enter your email";
      isValid = false;
    } else if (!isValidEmail(emailInput.value)) {
      errors.email = "Please enter a valid email address";
      isValid = false;
    }

    if (messageInput.value.trim() === "") {
      errors.message = "Please enter your message";
      isValid = false;
    }

    setFormErrors(errors);
    return isValid;
  };

  const isValidEmail = (email) => {
    // Simple email validation regex
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };

  const handleRecaptchaChange = (value) => {
    setRecaptchaValue(value);
  };

  const sendEmail = (e) => {
    e.preventDefault();

    if (validateForm() && recaptchaValue) {
      emailjs
        .sendForm(
          "service_dfknwrc",
          "template_b1a38lk",
          form.current,
          "gFfNOOOeMDEfjT7Cu"
        )
        .then((result) => {
          toast.success("Email was sent successfully");
        })
        .catch((error) => {
          toast.error(
            "Failed to send email. Please use my social media to contact me."
          );
        })
        .finally(() => {
          form.current.reset();
          setRecaptchaValue(null);
        });
    } else {
      toast.error("Please complete the reCAPTCHA");
    }
  };

  return (
    <div className="main-container fade-in" id={props.id || ""}>
      <ScreenHeading subHeading={"Lets Keep In Touch"} title={"Contact Me"} />
      <div className="central-form">
        <div className="icons">
          <a
            href="https://www.instagram.com/james_cope_2117/"
            target="_blank"
            class="icon icon--instagram"
          >
            <i class="ri-instagram-line">
              <RiInstagramLine />
            </i>
          </a>
          <a
            href="https://www.facebook.com/james.cope.399"
            target="_blank"
            class="icon icon--facebook"
          >
            <i class="ri-facebook-line">
              <RiFacebookLine />
            </i>
          </a>
          <a
            href="https://www.linkedin.com/in/james-c-736a8111a/"
            target="_blank"
            class="icon icon--linkedin"
          >
            <i class="ri-linkedin-line">
              <RiLinkedinLine />
            </i>
          </a>
          <a
            href="https://github.com/Jamesagcope"
            target="_blank"
            class="icon icon--github"
          >
            <i class="ri-github-line">
              <RiGithubLine />
            </i>
          </a>
        </div>

        <div className="back-form">
          <form ref={form} onSubmit={sendEmail}>
            <ToastContainer />
            <label htmlFor="name">Name</label>
            <input type="text" name="from_name" />
            {formErrors.name && <p className="error">{formErrors.name}</p>}

            <label htmlFor="email">Email</label>
            <input type="email" name="from_email" />
            {formErrors.email && <p className="error">{formErrors.email}</p>}

            <label htmlFor="message">Message</label>
            <textarea type="text" name="message" />
            {formErrors.message && (
              <p className="error">{formErrors.message}</p>
            )}

            <div className="recaptcha-container">
              <ReCAPTCHA
                sitekey="6LetNkomAAAAANQHNXuXpqHhGQ_twarNkZjPZW-l"
                size="compact"
                onChange={handleRecaptchaChange}
              />
            </div>

            <div className="send-btn">
              <button type="submit">
                send
                <i className="fa fa-paper-plane" />
              </button>
            </div>
          </form>
        </div>
      </div>
      <Footer />
    </div>
  );
}
