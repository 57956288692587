import React from "react";
import ImageGallery from "react-image-gallery";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import "react-image-gallery/styles/css/image-gallery.css";
import "./SnowBoarding.css";

import IMG1 from "./image-gallery/uniform.jpg";
import IMG2 from "./image-gallery/group.jpg";
import IMG3 from "./image-gallery/level2.jpg";
import IMG4 from "./image-gallery/silverstar.jpg";
import IMG5 from "./image-gallery/chairlift.jpeg";
import IMG6 from "./image-gallery/chairlift2.jpeg";
import IMG7 from "./image-gallery/snowing.jpeg";

export default function SnowBoarding(props) {
  let fadeInScreenHandler = (screen) => {
    if (screen.fadeScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };
  const fadeInSubscription = ScrollService.currentScreenFadeIn.subscribe(
    fadeInScreenHandler
  );

  const images = [
    {
      original: IMG1,
      thumbnail: IMG1,
    },
    {
      original: IMG2,
      thumbnail: IMG2,
    },
    {
      original: IMG3,
      thumbnail: IMG3,
    },
    {
      original: IMG4,
      thumbnail: IMG4,
    },
    {
      original: IMG5,
      thumbnail: IMG5,
    },
    {
      original: IMG6,
      thumbnail: IMG6,
    },
    {
      original: IMG7,
      thumbnail: IMG7,
    },
  ];

  const SCREEN_CONSTSANTS = {
    decription:
    "I spent 5 months in Canada, BC, working at Silverstar Mountain Resort and obtaining my CASI Level 1 and Level 2 Certification. During my time there, I made numerous new friends who shared the same interests. It was a transformative experience that taught me a lot about myself and the person I aspire to become. \n My days were dedicated to teaching and honing my snowboarding skills. I have a profound passion for immersing myself in nature, especially during the early morning rides. A typical day would begin with me heading up the mountain at 7 am to witness the breathtaking sunrise. This was followed by preparations for my first lesson at 9:15 am. Depending on the lesson duration, I would usually take a 30-minute lunch break at midday. Then, I would proceed to my next lesson, which typically marked the end of my teaching for the day.\nOne of the things I loved most was the freedom to ride at any time. If I didn't have a morning lesson, for example, I would seize the opportunity to further improve my riding skills.\nBeing in that environment allowed me to cultivate and enhance my abilities, pushing myself to new heights.",
    highlights: {
      bullets: [
      "Teaching", 
      "Coaching", 
      "Group Management", 
      "Planning", 
      "Easily Adaptable", 
      "Leadership"],
      heading: "List of Developed and new Skills:",
    }, 
  };

  const renderHighlight = () => {
    return SCREEN_CONSTSANTS.highlights.bullets.map((value, i) => (
      <div className="highlight" key={i}>
        <div className="highlight-blob"></div>
        <span>{value}</span>
      </div>
    ));
  };

  return (
    <div className="snowboard-container screen-container" id={props.id || ""}>
      <div className="snowboard-parent">
        <ScreenHeading title={"Snowboarding!!"} subHeading={"My Winter Adventures"} />
        <div className="snowboard-card">
          <div className="snowboard-details">
            <span className="snowboard-description">
              {SCREEN_CONSTSANTS.decription.split("\n").map((str, index) => (
                <React.Fragment key={index}>
                  {str}
                  <br />
                </React.Fragment>
              ))}
            </span>
            <div className="snowboard-highlights">
              <div className="highlight-heading">
                <span>{SCREEN_CONSTSANTS.highlights.heading}</span>
              </div>
              {renderHighlight()}
            </div>
          </div>
        </div>
        <div className="snowboard-card">
          <div className="image-carousel">
            <ImageGallery
              items={images}
              showFullscreenButton={true}
              showPlayButton={false}
              showNav={false}
              autoPlay={true}
              slideInterval={4000}
            />
          </div>
        </div>
      </div>
    </div>
  );
}
