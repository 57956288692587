import React, { useState } from "react";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import Gallery from "react-photo-gallery";
import "./Photography.css";
import { photos } from "./Photos";

export default function Photography(props) {
  let fadeInScreenHandler = (screen) => {
    if (screen.fadeScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };
  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);


  return (
    <div className="photography-container screen-container" id={props.id || ""}>
      <div className="photography-parent">
        <ScreenHeading title={"Photography"} subHeading={"Take a look at my Photography"} />
        <div className="photography-card">
          
            <Gallery
              photos={photos}
            />

        </div>
      </div>
    </div>
  );
}
