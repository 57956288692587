import React from 'react';
import './App.css';
import PortfolioContainer from './PortfolioContainer/PortfolioContainer';


function App() {
  return (
    <div className="App">
      <PortfolioContainer/>
    </div>
  );
}

export default App;
