import IMG1 from "./Photos/IMG_2093.jpg";
import IMG2 from "./Photos/IMG_2199.jpg";
import IMG3 from "./Photos/IMG_2214.jpg";
import IMG4 from "./Photos/IMG_2243.jpg";
import IMG5 from "./Photos/IMG_2273.jpg";
import IMG6 from "./Photos/IMG_2299.jpg";
import IMG7 from "./Photos/IMG_2346.jpg";
import IMG8 from "./Photos/IMG_2561.jpg";
import IMG9 from "./Photos/IMG_2375.png";

export const photos = [
    {
      src: IMG1,
      width: 2,
      height: 2
    },
    {
      src: IMG2,
      width: 4,
      height: 3
    },
    {
      src: IMG3,
      width: 3,
      height: 4
    },
    {
      src: IMG4,
      width: 3,
      height: 4
    },
    {
      src: IMG5,
      width: 3,
      height: 4
    },
    {
      src: IMG6,
      width: 4,
      height: 3
    },
    {
      src: IMG7,
      width: 3,
      height: 4
    },
    {
      src: IMG8,
      width: 2,
      height: 3
    },
    {
      src: IMG9,
      width: 4,
      height: 3
    }
  ];