import React from "react";
import Typical from "react-typical";
import ScrollService from "../../../utilities/ScrollService";
import {
  RiInstagramLine,
  RiGithubLine,
  RiLinkedinLine,
  RiFacebookLine,
} from "react-icons/ri";
import "./Profile.css";

export default function Profile() {
  return (
    <div className="profile-container">
      <div className="profile-parent">
        <div className="profile-details">
          <div className="header-logo">
            <span>James Cope</span>
          </div>
          <div className="colz">
            <div className="icons">
              <a
                href="https://www.instagram.com/james_cope_2117/"
                target="_blank"
                class="icon icon--instagram"
              >
                <i class="ri-instagram-line">
                  <RiInstagramLine />
                </i>
              </a>
              <a
                href="https://www.facebook.com/james.cope.399"
                target="_blank"
                class="icon icon--facebook"
              >
                <i class="ri-facebook-line">
                  <RiFacebookLine />
                </i>
              </a>
              <a
                href="https://www.linkedin.com/in/james-c-736a8111a/"
                target="_blank"
                class="icon icon--linkedin"
              >
                <i class="ri-linkedin-line">
                  <RiLinkedinLine />
                </i>
              </a>
              <a href="https://github.com/Jamesagcope" target="_blank" class="icon icon--github">
                <i class="ri-github-line">
                  <RiGithubLine />
                </i>
              </a>
            </div>
          </div>
          <div className="profile-details-name">
            <span className="primary-text">
              {" "}
              Hello, I'm <span className="highlighted-text">James</span>
            </span>
          </div>
          <div className="profile-details-role">
            <span className="primary-text">
              {" "}
              <h1>
                {" "}
                <Typical
                  loop={Infinity}
                  steps={[
                    "Ethusiastic Dev",
                    1000,
                    "Full stack Developer",
                    1000,
                    "Larvel Developer",
                    1000,
                    "PHP Developer",
                    1000,
                    "Level 2 CASI Snowboard Instructor",
                    1000,
                    "React Develop",
                    1000,
                  ]}
                />
              </h1>
              <span className="profile-role-tagline">
                Confident and presentable communicator, In the Computing world!
              </span>
            </span>
          </div>

          <div className="profile-options">
            <button
              className="btn primary-btn"
              onClick={() => ScrollService.scrollHandler.scrollToHireMe()}
            >
              {" "}
              Hire Me{" "}
            </button>
            <a href="James_Cope_Resume.pdf" download="James CV">
              <button className="btn highlighted-btn">Get Resume</button>
            </a>
          </div>
        </div>
        <div className="profile-picture">
          <div className="profile-picture-background"></div>
        </div>
      </div>
    </div>
  );
}
