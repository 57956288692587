import React, { useEffect } from "react";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import "./AboutMe.css";

export default function AboutMe(props) {
  let fadeInScreenHandler = (screen) => {
    if (screen.fadeScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };
  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);


  const SCREEN_CONSTSANTS = {
    decription:
    "A Systems Developer hailing from the United Kingdom, I hold a New Zealand passport and currently seek opportunities in Melbourne or Auckland. With a communication style characterized by confidence and professionalism, I have a strong desire to pursue a career in IT. Additionally, I have secured a winter job at Thredbo Mountain Resort until September. In terms of volunteering, I have contributed as a part-time Volunteer Police Officer with Avon and Somerset Police in Bristol, UK. Completing my initial training in January 2020 as a Special Constable, I gained valuable experience in effectively communicating with individuals from diverse backgrounds and age groups. My proficiency also encompasses the use of telephone and video communication for collaborative work. Throughout my academic journey, I showcased leadership skills while leading projects, and my involvement with the Police further enhanced my ability to navigate challenging situations and guide others towards effective solutions. Driven by ambition, I prioritize timely project completion and surpassing customer expectations. Additionally, I possess knowledge of business processes and customer service strategies. Notably, my experience as a Special Constable has sharpened my quick thinking and problem-solving skills, enabling me to make critical decisions under pressure.",      
    highlights: {
      bullets: [ 
      "I am a very good communicator. I am confident and polite with customers and colleagues.", 
      "I am comfortable using the telephone and video-calls in order to work with others.",
      "I am ambitious and keen to complete projects on time and exceed customer expectations.",
      "I can make important decisions in a stressful environment."
      ],
      heading: "Here are a few Highlighted Skills:",
    },
  };

  const renderHighlight = () => {
    return SCREEN_CONSTSANTS.highlights.bullets.map((value, i) => (
      <div className="highlight" key={i}>
        <div className="highlight-blob"></div>
        <span>{value}</span>
      </div>
    ));
  };

  return (
    <div className="about-me-container screen-container" id={props.id || ""}>
      <div className="about-me-parent">
        <ScreenHeading title={"About Me"} subHeading={"Why Choose Me?"} />
        <div className="about-me-card">
          <div className="about-me-profile"></div>
          <div className="about-me-details">
            <span className="about-me-description">
              {SCREEN_CONSTSANTS.decription}
            </span>
            <div className="about-me-highlights">
              <div className="highlight-heading">
                <span>{SCREEN_CONSTSANTS.highlights.heading}</span>
              </div>
              {renderHighlight()}
            </div>
            <div className="about-me-options">
              <div className="profile-optins">
                <button className="btn primary-btn" onClick={() => ScrollService.scrollHandler.scrollToHireMe()}> Hire Me </button>
                <a href="James_Cope_Resume.pdf" download="James CV">
                  <button className="btn highlighted-btn">Get Resume</button>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
