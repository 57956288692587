import React from "react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import "./Testimonial.css";

export default function Testimonial(props) {
  let fadeInScreenHandler = (screen) => {
    if (screen.fadeScreen !== props.id) return;
    Animations.animations.fadeInScreen(props.id);
  };
  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

    const options = {
        loop: true,
        margin: 0,
        nav: true,
        animateIn: "bounceInRight",
        animateOut: "bounceOutRight",
        dots: true,
        autoplay: true,
        smartSpeed: 1000,
        responsive: {
          0: {
            items: 1,
          },
          // 768: {
          //   items: 1,
          // },
          // 1000: {
          //   items: 3,
          // },
        },
      };

  return (
    <div>
      <ScreenHeading
        title={"Testimonial"}
        subHeading={"What My People Say about me"}
      />
      <section className="testimonial-section" id={props.id || ""}>
        <div className="container">
          <div className="row">
            <OwlCarousel className="owl-carousel" id="testimonial-carousel" {...options}>
              {/* repeat this section for more testimonials */}
              <div className="col-lg-12">
                <div className="testi-item">
                  <div className="testi-comment">
                    <p>
                      <i className="fa fa-quote-left" />
                      James was professional throughout his career at Evergreen. It was his first professional role after graduating from University and he was very keen to learn as much as possible.
                      He always asked lots of questions and if his code could be improved.
                      He progressed quickly and was soon taking on small projects and talking to customers directly via phone/video call, or in face-to-face meetings.
                      We were sorry to see him leave, but wish him all the best in his adventures.
                      <i className="fa fa-quote-right" />
                    </p>
                    <ul className="stars list-unstyled">
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                      <li>
                        <i className="fa fa-star"></i>
                      </li>
                    </ul>
                  </div>
                  <div className="client-info">
                    {/* <img
                      src="img/testimonial/man.png"
                      alt="no internet connection"
                    ></img> */}
                    <h5>Andy Pegg</h5>
                    <p>IT Director -- Evergreen Computing</p>
                  </div>
                </div>
              </div>
              {/* repeat this section for more testimonials */}
            </OwlCarousel>
          </div>
        </div>
      </section>
    </div>
  );
}
