import React, { useState, useEffect } from "react";
import { TbSchool } from "react-icons/fa";
import ScreenHeading from "../../utilities/ScreenHeading/ScreenHeading";
import ScrollService from "../../utilities/ScrollService";
import Animations from "../../utilities/Animations";
import "./Resume.css";

const Resume = (props) => {
  /* STATES */
  const [selectedBulletIndex, setSelectedBulletIndex] = useState(0);
  const [carousalOffsetStyle, setCarousalOffsetStyle] = useState({});

  let fadeInScreenHandler = (screen) => {
    if (screen.fadeInScreen !== props.id) return;

    Animations.animations.fadeInScreen(props.id);
  };
  const fadeInSubscription =
    ScrollService.currentScreenFadeIn.subscribe(fadeInScreenHandler);

  /* REUSABLE MINOR COMPONENTS */
  const ResumeHeading = (props) => {
    return (
      <div className="resume-heading">
        <div className="resume-main-heading">
          <div className="heading-bullet"></div>
          <span>{props.heading ? props.heading : ""}</span>
          {props.fromDate && props.toDate ? (
            <div className="heading-date">
              {props.fromDate + "-" + props.toDate}
            </div>
          ) : (
            <div></div>
          )}
        </div>
        <div className="resume-sub-heading">
          <span>{props.subHeading ? props.subHeading : ""}</span>
        </div>
        <div className="resume-heading-description">
          <span>{props.description ? props.description : ""}</span>
        </div>
      </div>
    );
  };

  /* STATIC RESUME DATA FOR THE LABELS*/
  const resumeBullets = [
    { label: "Work History", logoSrc: "work-history.svg" },
    { label: "Education", logoSrc: "education.svg" },
    { label: "Programming Skills", logoSrc: "programming-skills.svg" },
    { label: "Projects", logoSrc: "projects.svg" },
    // { label: "Interests", logoSrc: "interests.svg" },
  ];


  const programmingSkillsDetails = [
    { skill: "PHP", description: "2 years commercial exprience" },
    { skill: "Laravel", description: "2 years commercial exprience" },
    { skill: "SQL", description: "2 years commercial exprience" },
    { skill: "HTML", description: "2 years commercial exprience" },
    { skill: "CSS", description: "2 years commercial exprience" },
    { skill: "Node JS", description: "Personal Project Work" },
    { skill: "React JS", description: "Personal Project Work" },
  ];

  const projectsDetails = [
    {
      title: "Personal Portfolio Website",
      duration: { fromDate: "2023", toDate: "2023" },
      description:
        "A Personal Portfolio website to showcase all my details and projects at one place.",
      subHeading: "Technologies Used: React JS, Bootsrap",
    },
    {
      title: "Building and learning about computer hardware",
      duration: { fromDate: "2020", toDate: "2021" },
      description:
        "Build multiple levels of gaming computers, learning about compatablitiy.",
      subHeading:
        "Learnt to Build PC",
    },
  ];

  const resumeDetails = [
    /* WORK EXPERIENCE */
    <div className="resume-screen-container" key="work-experience">
      <div className="experience-container">
        <ResumeHeading
          heading={"Evergreen Compuing Ltd"}
          subHeading={"Full stack systems developer"}
          fromDate={"2021"}
          toDate={"Present"}
        />
        <div className="experience-description">
          <span className="resume-description-text">
            - I develop bespoke systems and that adapt to different customer
            needs and that they can access on the web.
          </span>
        </div>
        <div className="experience-description">
          <span className="resume-description-text">
            - I am currently in charge of 3 main systems and look after multiple
            systems with colleagues within the business.
          </span>
          <br />
          <span className="resume-description-text">
            - I interact with our customers in order of keeping them happy and
            satisfied with work or upcoming work needed on there CRM (systems).{" "}
          </span>
          <br />
          <span className="resume-description-text">
            - When we have current customers come to us for work, I quote them
            the amount it cost. They will then give me the go ahead to complete
            the work.
          </span>
          <br />
        </div>
      </div>
    </div>,
    
    /* EDUCATION */
    <div className="resume-screen-container" key="education">
      <ResumeHeading
        heading={"University of the West of England"}
        subHeading={"BACHELOR OF SCIENCE INFORMATION TECHNOLOGY"}
        description={"I have a strong background in computing and digital media, excelling in technical and ethical aspects. I've studied copyright, mastered project management, cryptography, and advanced database management. In web development, I've created interactive websites. My computing project addressed real-world issues, demonstrating my passion for social impact and problem-solving. I'm well-prepared to contribute to the industry."}
        fromDate={"2018"}
        toDate={"2020"}
      />
    </div>,

    /* PROGRAMMING SKILLS */
    <div
      className="resume-screen-container programming-skills-container"
      key="programming-skills"
    >
      {programmingSkillsDetails.map((skill, index) => (
        <div className="skill-parent" key={index}>
          <div className="heading-bullet"></div>
          <span>{skill.skill}</span>
          <div className="skill-percentage">
            <div className="skill-description">
              <span>{skill.description}</span>
            </div>
          </div>
        </div>
      ))}
    </div>,

    /* PROJECTS */
    <div className="resume-screen-container" key="projects">
      {projectsDetails.map((projectsDetails, index) => (
        <ResumeHeading
          key={index}
          heading={projectsDetails.title}
          subHeading={projectsDetails.subHeading}
          description={projectsDetails.description}
          fromDate={projectsDetails.duration.fromDate}
          toDate={projectsDetails.duration.toDate}
        />
      ))}
    </div>,

    // /* Interests */
    // <div className="resume-screen-container" key="interests">
    //   <ResumeHeading
    //     heading="Teaching"
    //     description="Apart from being a tech enthusiast and a code writer, i also love to teach people what i know simply because i believe in sharing."
    //   />
    //   <ResumeHeading
    //     heading="Music"
    //     description="Listening to soothing music is something i can never compromise with, skimming through Spotify's pop songs charts is at times the best stress reliever that i can get my hands on."
    //   />
    //   <ResumeHeading
    //     heading="Competitive Gaming"
    //     description="I like to challenge my reflexes a lot while competing in football games, pushing the rank and having interactive gaming sessions excites me the most."
    //   />
    // </div>,
  ];

  const handleCarousal = (index) => {
    let offsetHeight = 370;

    let newCarousalOffset = {
      style: { transform: "translateY(" + index * offsetHeight * -1 + "px)" },
    };

    setCarousalOffsetStyle(newCarousalOffset);
    setSelectedBulletIndex(index);
  };

  const getBullets = () => {
    return resumeBullets.map((bullet, index) => (
      <div
        onClick={() => handleCarousal(index)}
        className={
          index === selectedBulletIndex ? "bullet selected-bullet" : "bullet"
        }
        key={index}
      >
        <img
          className="bullet-logo"
          src={require(`../../assets/Resume/${bullet.logoSrc}`)}
          alt="B"
        />
        <span className="bullet-label">{bullet.label}</span>
      </div>
    ));
  };

  const getResumeScreens = () => {
    return (
      <div
        style={carousalOffsetStyle.style}
        className="resume-details-carousal"
      >
        {resumeDetails.map((ResumeDetail) => ResumeDetail)}
      </div>
    );
  };

  useEffect(() => {
    return () => {
      /* UNSUBSCRIBE THE SUBSCRIPTIONS */
      fadeInSubscription.unsubscribe();
    };
  }, [fadeInSubscription]);

  return (
    <div
      className="resume-container screen-container fade-in"
      id={props.id || ""}
    >
      <div className="resume-content">
        <ScreenHeading title={"Resume"} subHeading={"My formal Bio Details"} />
        <div className="resume-card">
          <div className="resume-bullets">
            <div className="bullet-container">
              <div className="bullet-icons"></div>
              <div className="bullets">{getBullets()}</div>
            </div>
          </div>

          <div className="resume-bullet-details">{getResumeScreens()}</div>
        </div>
      </div>
    </div>
  );
};

export default Resume;


/*
Education:

I have completed a comprehensive and rigorous education in the field of computing and digital media, culminating in a strong academic record. In my second year, I delved into various subjects that provided me with a deep understanding of both the technical and ethical aspects of the industry. I explored the intricacies of copyright and property rights, gaining valuable insights into the legal framework surrounding digital content. Additionally, I developed a keen sense of professional responsibility within the workplace and the importance of adhering to ethical standards.

One of the highlights of my studies was the subject of requirements engineering, which complemented my software development skills. Through analyzing successful and failed projects, I gained valuable insights into the key factors influencing their outcomes. This knowledge proved instrumental in my understanding of project management and contributed to my ability to effectively plan and execute software projects.

I further honed my technical expertise through subjects such as cryptography, where I not only appreciated the mathematical foundations of programming but also gained practical experience in encrypting and decrypting messages using different algorithms. This deepened my understanding of data security and privacy considerations in the digital realm.

In the field of database management, I excelled in the advanced database subject, acquiring a strong understanding of data organization, storage, and retrieval. This knowledge has equipped me with the skills to effectively manage and access large volumes of data within various database systems.

Additionally, I demonstrated proficiency in advanced topics in web development, where I leveraged APIs to pull and upload data, specifically focusing on exchange rates. This experience allowed me to design and develop websites that provided users with dynamic and interactive features, such as currency conversion calculators.

Lastly, as part of my computing project, I had the opportunity to tackle a real-world issue related to the relationship between police officers and citizens in different communities. I developed a mobile application that facilitated access to crime information, laws, and allowed users to provide feedback and report incidents. This project not only ignited my passion for social impact but also showcased my ability to develop innovative solutions to complex problems.

Overall, my education has provided me with a solid foundation in computing, encompassing a range of technical, ethical, and professional skills. I am equipped with the knowledge and expertise necessary to excel in the field, and I am eager to contribute my abilities to make a meaningful difference in the industry.
*/